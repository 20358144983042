import { addNotification, removeNotification } from "../notifications"
import { createError } from "ems-device-manager/src/lib/redux/slices/notifications/utils"
import { api } from "@/shared/lib/redux/slices/api-slice"
import { CONNECTION_ERROR_NOTIFICATION_ID, CONNECTION_CHECK_INTERVAL } from "@/shared/lib/dataloading/fetch-util"

export const DEFAULT_CONNECTION_QUERY_OPTIONS = {
  pollingInterval: CONNECTION_CHECK_INTERVAL,
  refetchOnReconnect: true,
}
export const connectionApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    checkConnection: build.query<void, void>({
      query: () => "/system/ping",
      providesTags: ["connection"],
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled
          dispatch(removeNotification({ itemOrId: CONNECTION_ERROR_NOTIFICATION_ID }))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          dispatch(
            addNotification({
              item: createError(
                CONNECTION_ERROR_NOTIFICATION_ID,
                e?.status === 500 ? "connectionErrorInternal" : "connectionError",
                "global_error",
              ),
            }),
          )
        }
      },
    }),
  }),
})

export const { useCheckConnectionQuery } = connectionApi
