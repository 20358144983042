import { NotificationItem, NotificationType } from "@/shared/lib/redux/slices/notifications"
import { DateTime } from "luxon"

/**
 * Creates a new Error NotificationItem
 * @param {string} id ID of the NotificationItem.
 * @param {string} messageKey message key of the NotificationItem that will be used to extract translation with useTranslation() from "EmsNotificationMessage" in en.json.
 * @param {NotificationType} type Message type. Defaults to "error". "global_error" cannot be dismissed and blocks the application
 * @param {number} timeout the timeout in milliseconds. After this timeout the message will disappear automatically.
 **/
export function createError(
  id: string,
  messageKey: string,
  type: NotificationType = "error",
  timeout?: number,
): NotificationItem {
  return createNotification(id, messageKey, type, timeout)
}

/**
 * Creates a new Error NotificationItem
 * @param {string} id ID of the NotificationItem.
 * @param {string} messageKey message key of the NotificationItem that will be used to extract translation with useTranslation() from "EmsNotificationMessage" in en.json.
 * @param {boolean} translate set to true to display message as is, without extracting translation
 **/
export function createErrorWithCustomMessage(id: string, messageKey: string, translate: boolean): NotificationItem {
  return {
    id: id,
    notificationType: "error",
    messageKey: messageKey,
    timestampAdded: DateTime.now().toISO(),
    translate: translate,
  }
}

/**
 * Creates a new NotificationItem
 * @param {string} id ID of the NotificationItem.
 * @param {string} messageKey message key of the NotificationItem that will be used to extract translation with useTranslation() from "EmsNotificationMessage" in en.json.
 * @param {NotificationType} type Message type.
 * @param {number} timeout the timeout in milliseconds. After this timeout the message will disappear automatically.
 **/
export function createNotification(
  id: string,
  messageKey: string,
  type: NotificationType,
  timeout?: number,
): NotificationItem {
  return {
    id,
    notificationType: type,
    messageKey,
    timestampAdded: DateTime.now().toISO(),
    timeout,
  }
}

/**
 * Helper function that returns title and detail of the error if provided by the api.
 * @param {any} e Error thrown by the failed query.
 */
export const getApiErrorMessage = (e: any): string => {
  const title: string | undefined = e?.error?.data?.title
  const detail: string | undefined = e?.error?.data?.detail

  if (title && detail) {
    return `${title} ${detail}`
  } else {
    return "Unidentified error. Check logs for more information."
  }
}

export const SUCCESS_MESSAGE_TIMEOUT = 3000
export const ERROR_MESSAGE_TIMEOUT = 5000
