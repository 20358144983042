import type { AuthContextProps } from "react-oidc-context"

/**
 * Trigger silent refresh of access token if the session (refresh token) is not expired
 * @param authContextProps
 */
export const triggerSilentSignIn = async (authContextProps: AuthContextProps) => {
  await authContextProps.signinSilent()
}

/**
 * Trigger sign out redirect.
 * @param authContextProps
 */
export const triggerSignoutRedirect = async (authContextProps: AuthContextProps) => {
  await authContextProps.signoutRedirect()
}

/**
 *  Used to trigger silent sign in after connection was re-established
 * @param authContextProps
 * @param isConnectionError
 */
export function handleConnectionReestablished(authContextProps: AuthContextProps, isConnectionError: boolean) {
  if (authContextProps.error !== undefined && !isConnectionError) {
    void triggerSilentSignIn(authContextProps)
  }
}

/**
 *  Used to trigger signOutRedirect when refresh token expires
 *  'invalid_grant' is the error identification received on refresh token expiration
 * @param authContextProps
 */
export function handleInvalidGrant(authContextProps: AuthContextProps) {
  if (authContextProps.error && "error" in authContextProps.error && authContextProps.error.error === "invalid_grant") {
    triggerSignoutRedirect(authContextProps)
  }
}
